import React from "react";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { Box, createTheme, responsiveFontSizes } from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";

const b = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: green[600],
    },
    background: {
      default: grey[200],
    },
  },

  typography: {
    fontFamily: ["Lora"].join(","),
  },
  shape: {
    borderRadius: 10,
  },
});
const c = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: blue[700],
    },
    background: {
      default: grey[200],
    },
  },

  typography: {
    fontFamily: ["Ubuntu Mono"].join(","),
  },
  shape: {
    borderRadius: 5,
  },
});
const d = createTheme({
  palette: {
    mode: "dark",
    secondary: {
      main: "#bd362c",
    },
    primary: { main: "#f2e959" },
  },

  typography: {
    fontFamily: ["Ubuntu Mono"].join(","),
  },
  shape: {
    borderRadius: 5,
  },
});

const e = createTheme({
  palette: {
    primary: {
      main: "#FFFF",
    },
  },
  typography: {
    fontFamily: ["Source Sans 3"].join(","),
  },
  shape: {
    borderRadius: 5,
  },
  components: {},
});

export default function TopLayout({ children, theme, ...rest }) {
  const [domain, setDomain] = React.useState(null);
  React.useEffect(() => {
    setDomain(window?.location.origin);
  }, []);
  return (
    <Box>
      <Box
        sx={{
          display:
            typeof window !== `undefined` &&
            (domain === "http://localhost:8000" ||
              domain === "https://vestimak.mk")
              ? "block"
              : "none",
        }}
      >
        <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
      </Box>
      <Box
        sx={{
          display:
            typeof window !== `undefined` &&
            (domain === "http://localhost:8001" ||
              domain === "https://vestimk.mk")
              ? "block"
              : "none",
        }}
      >
        <ThemeTopLayout theme={responsiveFontSizes(b)}>
          {children}
        </ThemeTopLayout>
      </Box>
      <Box
        sx={{
          display:
            typeof window !== `undefined` &&
            (domain === "http://localhost:8002" || domain === "https://utre.mk")
              ? "block"
              : "none",
        }}
      >
        <ThemeTopLayout theme={responsiveFontSizes(c)}>
          {children}
        </ThemeTopLayout>
      </Box>
      {typeof window !== `undefined` &&
        (domain === "http://localhost:8003" ||
          domain === "https://24mk.mk") && (
          <Box
            sx={{
              display:
                typeof window !== `undefined` &&
                (domain === "http://localhost:8003" ||
                  domain === "https://24mk.mk")
                  ? "block"
                  : "none",
            }}
          >
            <ThemeTopLayout theme={responsiveFontSizes(d)}>
              {children}
            </ThemeTopLayout>
          </Box>
        )}
      {typeof window !== `undefined` &&
        (domain === "http://localhost:8004" ||
          domain === "https://mk24.mk") && (
          <Box
            sx={{
              display:
                typeof window !== `undefined` &&
                (domain === "http://localhost:8004" ||
                  domain === "https://mk24.mk")
                  ? "block"
                  : "none",
            }}
          >
            <ThemeTopLayout theme={responsiveFontSizes(e)}>
              {children}
            </ThemeTopLayout>
          </Box>
        )}
    </Box>
  );
}
