import { green, grey, orange, pink, red, yellow } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#d7210a",
    },
    background: {
      default: grey[200],
    },
  },
  shape: { borderRadius: 0 },
});

export default responsiveFontSizes(theme);
